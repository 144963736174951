<template>

    <div class="taa-section taa-heading" style="padding-top: 84px">
        <div class="taa-container">
            <img src="/img/taa-studios-logo.png" style="height: 124px; width: 124px; margin-bottom: 48px">
            <div class="taa-heading--inner">
                <div class="taa-heading--copy">TAA Studios</div>
            </div>
        </div>
    </div>

    <div class="taa-section taa-project">
        <div class="taa-container">
            <div class="taa-project--inner">
                <div class="taa-project--brief">
                    <div class="taa-blurb--headline">
                        Innovation through Internal Development.
                    </div>
                    <div class="taa-blurb--copy">
                        TAA Studios is where The Acceleration Agency internally develops projects to explore cutting
                        edge innovation across a wide variety of industries including virtual reality, marketplaces,
                        Web3, and Digital Twins. We then leverage that experience to serve our clients.
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="taa-divider"></div>

    <!-- Gigstan -->

    <div class="taa-section taa-blurb">
        <div class="taa-container">
            <div class="taa-blurb--inner">
                <div class="taa-blurb--headline">Gigstan</div>
                <div class="taa-tag" href="#">
                    <div class="taa-tag--title">
                        <div class="taa-tag--copy">TAA STUDIOS & fanvention</div>
                        <svg class="taa-tag--icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 18">
                            <path fill="currentColor"
                                  d="M6.31619 17.9998L0 0L9.52546 0.000199749L16 18L6.31619 17.9998Z"/>
                        </svg>
                    </div>
                    <div class="taa-tag--extra">crowdfunding live music</div>
                </div>
                <div class="taa-blurb--copy">
                    <p class='taa-blurb--copy-large'>TAA Studios and Austin, Texas stealth start-up FanVention have
                        incubated the world’s first 3-sided marketplace for crowdfunded live music: GigStan.</p>
                    <p>After years of hard times during the pandemic the Live Music industry has been hit hard. TAA
                        observed that the rebound provided an ideal opportunity for a new better approach to improve the
                        live music value chain.</p>
                    <p>The industry today is burdened with very high levels of frustration with the current economics of
                        ticketing and booking, combined with the massive untapped revenue lost with unused artist and
                        venue capacity, and pent-up demand for live experiences.</p>
                    <p>Using GigStan’s iOS and Android mobile apps fans can see more shows, artists can play more shows,
                        and venues can host more shows.</p>
                </div>
                <a href="https://gigstan.com" class="taa-link" target="_blank">
                    <div class="taa-link--action">
                        <div class="taa-link--copy">Gigstan.com</div>
                        <svg class="taa-link--icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.16 9.16">
                            <path fill="currentColor"
                                  d="M1,9.16a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42L5.75,2H1.8a1,1,0,0,1,0-2H8.16a1.22,1.22,0,0,1,.38.07h0a1.26,1.26,0,0,1,.32.21h0a.91.91,0,0,1,.2.32A.92.92,0,0,1,9.16,1V7.36a1,1,0,1,1-2,0V3.41L1.71,8.87A1,1,0,0,1,1,9.16Z"
                            ></path>
                        </svg>
                    </div>
                </a>
            </div>
        </div>
    </div>

    <div class="taa-section taa-screen">
        <div class="taa-container">
            <div class="taa-screen--inner">
                <div class="taa-screen--image">
                    <img class="taa-screen--src" src="/img/gigstan-hero.jpg" alt="">
                </div>
            </div>
        </div>
    </div>

    <div class="taa-divider"></div>

    <!-- Dragon Fight VR -->

    <div class="taa-section taa-blurb">
        <div class="taa-container">
            <div class="taa-blurb--inner">
                <div class="taa-blurb--headline">Dragon Fight VR</div>
                <div class="taa-tag" href="#">
                    <div class="taa-tag--title">
                        <div class="taa-tag--copy">TAA STudios</div>
                        <svg class="taa-tag--icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 18">
                            <path fill="currentColor"
                                  d="M6.31619 17.9998L0 0L9.52546 0.000199749L16 18L6.31619 17.9998Z"/>
                        </svg>
                    </div>
                    <div class="taa-tag--extra">virtual reality experiment</div>
                </div>
                <div class="taa-blurb--copy">
                    <p class='taa-blurb--copy-large'>
                        Dragon Fight VR is an internal experiment by our team to capture the "essence" of a dragon
                        fight, the classic penultimate experience of every fantasy story. We wanted to create a short
                        visceral, realistic experience stripped of user interface, lobbies, scores, levels, and other
                        visual intrusions that might distract. There were some key elements of fighting a dragon that we
                        wanted to capture and we felt VR was uniquely qualified to convey:
                    </p>
                    <ul>
                        <li>Face to face with a large scale hostile creature trying to kill you</li>
                        <li>Using a sword and a shield</li>
                        <li>Fire!</li>
                        <li>Nowhere to run!</li>
                    </ul>
                    <p>
                        This started out as an internal experiment to learn some things about VR’s capabilities, be
                        reductionist in our designs, etc. We felt that the experiment was successful enough to release
                        as a free experience to share. Enjoy!
                    </p>
                </div>
                <a href="https://store.steampowered.com/app/1073890/Dragon_Fight_VR/" class="taa-link" target="_blank">
                    <div class="taa-link--action">
                        <div class="taa-link--copy">Download on STEAM</div>
                        <svg class="taa-link--icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.16 9.16">
                            <path fill="currentColor"
                                  d="M1,9.16a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42L5.75,2H1.8a1,1,0,0,1,0-2H8.16a1.22,1.22,0,0,1,.38.07h0a1.26,1.26,0,0,1,.32.21h0a.91.91,0,0,1,.2.32A.92.92,0,0,1,9.16,1V7.36a1,1,0,1,1-2,0V3.41L1.71,8.87A1,1,0,0,1,1,9.16Z"
                            ></path>
                        </svg>
                    </div>
                </a>
            </div>

        </div>
    </div>

    <div class="taa-section taa-screen">
        <div class="taa-container">
            <div class="taa-screen--inner">
                <div class="taa-screen--image">
                    <img class="taa-screen--src" src="/img/dragon-fight-vr-hero.jpg" alt="">
                </div>
            </div>
        </div>
    </div>

    <div class="taa-divider"></div>

    <!-- Project Gemini -->

    <div class="taa-section taa-blurb">
        <div class="taa-container">
            <div class="taa-blurb--inner">
                <div class="taa-blurb--headline">Project Gemini</div>
                <div class="taa-tag" href="#">
                    <div class="taa-tag--title">
                        <div class="taa-tag--copy">TAA Studios</div>
                        <svg class="taa-tag--icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 18">
                            <path fill="currentColor"
                                  d="M6.31619 17.9998L0 0L9.52546 0.000199749L16 18L6.31619 17.9998Z"/>
                        </svg>
                    </div>
                    <div class="taa-tag--extra">real time active digital twin</div>
                </div>
                <div class="taa-blurb--copy">
                    <p class='taa-blurb--copy-large'>
                        TAA Studios presents Project Gemini: an Active Digital Twin Public Demo and Framework. The
                        Acceleration Agency's headquarters in Austin, Texas is digitally replicated and instrumented
                        with a Dense Sensor Fabric for real-time and historical spatial computation of people and
                        assets.
                    </p>
                    <p>
                        Multiple abstraction layers allow clients to connect a variety of sensors, databases/CRMs, and
                        Object Integration tools. Project Gemini includes integration examples of SalesForce and Silicon
                        Labs sensor/tag network to show real time location, temperature, audio, and orientation.
                    </p>
                </div>
                <a href="#/project-gemini" class="taa-link">
                    <div class="taa-link--action">
                        <div class="taa-link--copy">learn more about project gemini</div>
                        <svg class="taa-link--icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.16 9.16">
                            <path fill="currentColor"
                                  d="M1,9.16a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42L5.75,2H1.8a1,1,0,0,1,0-2H8.16a1.22,1.22,0,0,1,.38.07h0a1.26,1.26,0,0,1,.32.21h0a.91.91,0,0,1,.2.32A.92.92,0,0,1,9.16,1V7.36a1,1,0,1,1-2,0V3.41L1.71,8.87A1,1,0,0,1,1,9.16Z"
                            ></path>
                        </svg>
                    </div>
                </a>
            </div>

        </div>
    </div>

    <div class="taa-section taa-screen">
        <div class="taa-container">
            <div class="taa-screen--inner">
                <div class="taa-screen--image">
                    <img class="taa-screen--src" src="/img/gemini-hero.jpg" alt="">
                </div>
            </div>
        </div>
    </div>

    <!-- Next Project -->

    <div class="taa-space"></div>

    <div class="taa-next-project">

        <div class="taa-section taa-subhead">
            <div class="taa-container">
                <div class="taa-subhead--inner">
                    <svg class="taa-slash" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 18">
                        <path fill="currentColor" d="M6.31619 17.9998L0 0L9.52546 0.000199749L16 18L6.31619 17.9998Z"/>
                    </svg>
                    <div class="taa-subhead--copy">Next Solution</div>
                </div>
            </div>
        </div>

        <div class="taa-section taa-blurb">
            <div class="taa-container">
                <div class="taa-blurb--inner">
                    <router-link :to="'experience'" class="taa-link">
                        <div class="taa-link--action">
                            <div class="taa-link--copy">Experience Orchestration</div>
                            <svg class="taa-link--icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.16 9.16">
                                <path fill="currentColor"
                                      d="M1,9.16a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42L5.75,2H1.8a1,1,0,0,1,0-2H8.16a1.22,1.22,0,0,1,.38.07h0a1.26,1.26,0,0,1,.32.21h0a.91.91,0,0,1,.2.32A.92.92,0,0,1,9.16,1V7.36a1,1,0,1,1-2,0V3.41L1.71,8.87A1,1,0,0,1,1,9.16Z"/>
                            </svg>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>

        <div class="taa-section taa-screen">
            <div class="taa-container">
                <div class="taa-screen--inner">
                    <div class="taa-screen--image">
                        <img class="taa-screen--src" src="img/experience-next.jpg" alt=""/>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="taa-space"></div>

</template>

<script>

import {LocalStorageKeys} from '@/libs/const.js'
import ls from 'local-storage';
import site from './site.json';


export default {
    name: 'TAAStudios',
    components: {},
    data: function () {
        return {
            isFirstTimeOnSite: 0,
            info: null
        }
    },
    watch: {
        $route() {
            this.info = site['sections'][this.$route.name]
            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 1)
        }
    },
    mounted() {
        window.scrollTo(0, 0);
    },
    created() {

        this.info = site['sections'][this.$route.name]

        this.isFirstTimeOnSite = ls(LocalStorageKeys.FirstTimeOnSite);
        if (this.isFirstTimeOnSite === null || this.isFirstTimeOnSite === false) {
            ls(LocalStorageKeys.FirstTimeOnSite, false);
        } else {
            this.isFirstTimeOnSite = true;
        }
    }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}

</style>
