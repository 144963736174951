<template>
            <!-- eslint-disable -->
            <div class="taa-section taa-blurb">
                <div class="taa-container">
                    <div class="taa-blurb--inner">
                        <div class="taa-blurb--logo"><img srcset="img/logo--port-corpus-christi.png 100w" sizes="50px" src="img/logo--port-corpus-christi.png"/></div>
                        <div class="taa-tag" href="#">
                            <div class="taa-tag--title">
                                <div class="taa-tag--copy">Port of Corpus Christi</div>
                                <svg class="taa-tag--icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 18">
                                    <path fill="currentColor" d="M6.31619 17.9998L0 0L9.52546 0.000199749L16 18L6.31619 17.9998Z"></path>
                                </svg>
                            </div>
                            <div class="taa-tag--extra">Active Digital Twin</div>
                        </div>
                        <div class="taa-blurb--headline">OPTICS: Active Digital Twin</div>
                    </div>
                </div>
            </div>
            
            <div class="taa-section taa-video">
                <div class="taa-container">
                    <div class="taa-video--inner">
                          <video-player  class="taa-video--src"
                                         ref="videoPlayer"
                                         :options="playerOptions"
                                         >
                        </video-player>
                      </div>
                </div>
            </div>

            <div class="taa-section taa-blurb full-width">
                <div class="taa-container">
                    <div class="taa-blurb--inner">
                        <div class="taa-blurb--headline">OPTICS</div>
                        <div class="taa-blurb--subhead">Overall Port Tactical Information Computer System</div>
                        <div class="taa-blurb--copy">
                            <p class="taa-blurb--copy-large">A high-resolution, dynamic 3D Active Digital Twin of the Port of Corpus Christi (PCCA). OPTICS displays active vessel presence and movement information from ship transponders (AIS) and security information to provide a cohesive and tactical overview of Port situational awareness in real-time. The project is funded through a Port Security Grant from FEMA.</p>
                            <p>OPTICS is a custom solution built with <a href="/">The Acceleration Agency</a>’s <a href="/#/project-gemini">Project Gemini</a> Active Digital Twin platform.</p>
                            <a href="#" class="taa-link">
                                <div class="taa-link--action">
                                    <router-link class="taa-link--copy" to="#contact" v-scroll-to="'#contact'">Contact us for a demo</router-link>

                                    <svg class="taa-link--icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.16 9.16" data-v-8fbe9336="">
                                        <path fill="currentColor" d="M1,9.16a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42L5.75,2H1.8a1,1,0,0,1,0-2H8.16a1.22,1.22,0,0,1,.38.07h0a1.26,1.26,0,0,1,.32.21h0a.91.91,0,0,1,.2.32A.92.92,0,0,1,9.16,1V7.36a1,1,0,1,1-2,0V3.41L1.71,8.87A1,1,0,0,1,1,9.16Z" data-v-8fbe9336=""></path>
                                    </svg>
                                </div>
                            </a>
                        </div>
                        <div class="taa-logo-line">
                            <img srcset="img/project-gemini.png 100w" sizes="50px" src="img/project-gemini.png"/>
                            <img srcset="img/autonomy-institute.png 100w" sizes="50px" src="img/autonomy-institute.png"/>
                            <img srcset="img/unity-logo.png 100w" sizes="50px" src="img/unity-logo.png"/>                    
                            <img srcset="img/esri-logo.png 100w" sizes="50px" src="img/esri-logo.png"/>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="taa-section taa-blurb full-width">
                <div class="taa-container">
                    <div class="taa-blurb--headline">Solutions</div>
                    <div class="taa-cols">
                        <div class="taa-col">
                            <div class="taa-blurb--subhead">Why build an Active Digital Twin for the Port of Corpus Christi?</div>
                            <div class="taa-blurb--copy">
                                <p class="taa-blurb--copy-large">There were multiple solutions gained by an Active Digital Twin including Faster Security Context Assessment, Merging of Multiple Systems, Optimization of Vessel Traffic, and Long Term Planning. Fundamentally a digital twin facilitates all of these better than traditional tools because it presents itself more like the real world instead of an abstraction.</p>
                            </div>
                        </div>
                        <div class="taa-col">
                            <div class="taa-blurb--logo"><img srcset="img/logo--port-corpus-christi.png 100w" sizes="50px" src="img/logo--port-corpus-christi.png"/></div>
                            <ul class="taa-blurb--list">
                                <li>1926 Operations Start</li>
                                <li>Largest Port in US in Total Revenue Tonnage</li>
                                <li>5th Largest in Total Tonnage</li>
                                <li>Nation’s Largest Energy Export Gateway</li>
                                <li>Direct Docks, Refineries, Rail, and Highway Access</li>
                                <li>54’ Deep Channel (in process)</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="taa-image"><img class="taa-image--src" src="img/optics-solutions.jpg" alt=""></div>
            
            <div class="taa-space"></div>
            
            <div class="taa-section taa-blurb full-width">
                <div class="taa-container">
                    <div class="taa-cols">
                        <div class="taa-col">
                            <div class="taa-blurb--subhead">Merging of Multiple Systems</div>
                            <div class="taa-blurb--copy">
                                <p class="taa-blurb--copy-large">Large infrastructure sites, like the Port of Corpus Christi, take multiple digital tools to operate (10 - 30). OPTICS consumes the data streams from those various systems and displays them on a “<b>Single Pane of Glass</b>”.</p>
                            </div>
                        </div>
                        <div class="taa-col">
                            <ul class="taa-blurb--list">
                                <li>ArcGIS Custom Elevation Data: Coastlines and Water Display</li>
                                <li>ESRI / ArcGIS 3D Building Layer: Docks, Buildings, Refineries, etc.</li>
                                <li>ESRI / ArcGIS Feature Layers: 3 Types</li>
                                <li>ESRI / ArcGIS Maps Base Layers: 6 Types</li>
                                <li>ESRI / ArcGIS Maps SDK for Unity</li>
                                <li>Facility Information: Name, Location, Type, Description</li>
                                <li>AIS: Vessel Locations, Types, Sizes, Headings, Images</li>
                                <li>NOAA Ports: Aid to Navigation Sensor Data</li>
                                <li>CAD: Computer Aided Dispatch System</li>
                                <li>USCG Marsec level</li>
                                <li>Weather.gov: Current Weather and Forecasts</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="taa-image"><img class="taa-image--src" src="img/optics-apis.png" alt=""></div>
            
            <div class="taa-section taa-blurb full-width">
                <div class="taa-container">
                    <div class="taa-cols">
                        <div class="taa-col">
                            <div class="taa-blurb--subhead">Faster Assessment</div>
                            <div class="taa-blurb--copy">
                                <p class="taa-blurb--copy-large">As we add more and more sensors and data to our infrastructure the cognitive load required to process that data increases. A digital twin can present the data in a form that our brains evolved to process quickly: the real world. OPTICS takes the data the port is processing and presents it as a real world representation therefore giving operators the ability to assess context faster, especially in security situations.</p>
                            </div>
                        </div>
                        <div class="taa-col">
                            <div class="taa-blurb--subhead">Optimization</div>
                            <div class="taa-blurb--copy">
                                <p class="taa-blurb--copy-large">Using vessel transponder information and machine learning OPTICS provides predicted location information for all vessels. Over time this information will be used to optimize vessel traffic flow to increase cargo throughput and annual revenue while maintaining safety.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="taa-section taa-blurb full-width">
                <div class="taa-container">
                    <div class="taa-blurb--subhead">Long Term Planning</div>
                    <div class="taa-blurb--copy">
                        <p class="taa-blurb--copy-large">Port operators can use OPTICS for long term planning by changing the visibility of certain elements. For example the New Harbor Bridge is currently under construction but in OPTICS the bridge can be made visible as if it is complete to simulate changes in traffic and navigation.</p>
                    </div>
                </div>
            </div>
            
            <div class="taa-section taa-compare">
                <div class="taa-container">
                    <ImgComparisonSlider>
                        <img slot="first" src="img/optics-after.jpg" />
                        <img slot="second" src="img/optics-before.jpg" />
                        <div slot="handle" class="taa-compare--handle"></div>
                    </ImgComparisonSlider>
                </div>
            </div>
            
            <div class="taa-space"></div>
            
            <div class="taa-section taa-blurb full-width group-below">
                <div class="taa-container">
                    <div class="taa-blurb--headline">Current Features</div>
                    <div class="taa-blurb--subhead">Maps</div>
                    <div class="taa-blurb--copy">
                        <p class="taa-blurb--copy-large">Fifty square miles around the Port of Corpus Christi area is displayed using a combination of ArcGIS base layers (several different styles can be selected to display), 3D buildings, and a water layer to present a highly detailed and realistic view. The Port’s area of responsibility covers this 50 square mile area.</p>
                    </div>
                </div>
            </div>
            
            <div class="taa-section taa-slider">
                <div class="taa-container">

                    <Splide class="splide" :options='options3' aria-label="My Favorite Images">
                        <SplideSlide>
                          <img src="img/optics-maps-1.jpg" >
                        </SplideSlide>
                        <SplideSlide>
                          <img src="img/optics-maps-2.jpg" >
                        </SplideSlide>
                        <SplideSlide>
                          <img src="img/optics-maps-3.jpg" >
                        </SplideSlide>
                    </Splide>

                </div>
            </div>
            
            <div class="taa-section taa-blurb full-width group-below">
                <div class="taa-container">
                    <div class="taa-blurb--subhead">Filtering</div>
                    <div class="taa-blurb--copy">
                        <p class="taa-blurb--copy-large">OPTICS provides multiple ways to filter and search Vessels, Facilities and Incidents by type, subtype, name, and identifier (callsign).</p>
                    </div>
                </div>
            </div>
            
            <div class="taa-section taa-slider">
                <div class="taa-container">


                    <Splide class="splide" :options='options1'>
                        <SplideSlide>
                          <img src="img/optics-filtering-1.jpg" >
                        </SplideSlide>
                        <SplideSlide>
                          <img src="img/optics-filtering-2.jpg" >
                        </SplideSlide>
                        <SplideSlide>
                          <img src="img/optics-filtering-3.jpg" >
                        </SplideSlide>
                        <SplideSlide>
                          <img src="img/optics-filtering-4.jpg" >
                        </SplideSlide>
                    </Splide>


                </div>
            </div>
            
            <div class="taa-section taa-blurb full-width group-below">
                <div class="taa-container">
                    <div class="taa-blurb--subhead">Facilities</div>
                    <div class="taa-icons">
                        <div class="taa-icon">
                            <img class="taa-icon--img" src="img/optics-facilities-facilities.png" />
                            <span class="taa-icon--label">Facilities</span>
                        </div>
                        <div class="taa-icon">
                            <img class="taa-icon--img" src="img/optics-facilities-dock.png" />
                            <span class="taa-icon--label">Dock</span>
                        </div>
                        <div class="taa-icon">
                            <img class="taa-icon--img" src="img/optics-facilities-industry.png" />
                            <span class="taa-icon--label">Industry</span>
                        </div>
                    </div>
                    <div class="taa-blurb--copy">
                        <p class="taa-blurb--copy-large">Hundreds of Key Port Facility Locations are displayed in OPTICS including Oil Docks, Cargo Docks, Administration Buildings, Security Cameras, Bridges and Security Gates are displayed including Private and Port maintained facilities. The details for the facilities can be displayed in a list view that can be sorted and filtered and/or by selecting an individual facility.</p>
                        <p>NOTE: Displayed security locations (gates & cameras) is synthetic data to comply with CJIS requirements</p>
                    </div>
                </div>
            </div>
            
            <div class="taa-section taa-slider">
                <div class="taa-container">


                    <Splide class="splide" :options='options1'>
                        <SplideSlide>
                          <img src="img/optics-facilities-1.jpg" >
                        </SplideSlide>
                        <SplideSlide>
                          <img src="img/optics-facilities-2.jpg" >
                        </SplideSlide>
                        <SplideSlide>
                          <img src="img/optics-facilities-3.jpg" >
                        </SplideSlide>
                        <SplideSlide>
                          <img src="img/optics-facilities-4.jpg" >
                        </SplideSlide>
                    </Splide>


                </div>
            </div>
            
            <div class="taa-section taa-blurb full-width group-below">
                <div class="taa-container">
                    <div class="taa-blurb--subhead">Vessels</div>
                    <div class="taa-icons">
                        <div class="taa-icon">
                            <img class="taa-icon--img" src="img/optics-vessels-default.png" />
                            <span class="taa-icon--label">Default</span>
                        </div>
                        <div class="taa-icon">
                            <img class="taa-icon--img" src="img/optics-vessels-tanker.png" />
                            <span class="taa-icon--label">Tanker</span>
                        </div>
                        <div class="taa-icon">
                            <img class="taa-icon--img" src="img/optics-vessels-cargo.png" />
                            <span class="taa-icon--label">Cargo</span>
                        </div>
                        <div class="taa-icon">
                            <img class="taa-icon--img" src="img/optics-vessels-tug.png" />
                            <span class="taa-icon--label">Tug</span>
                        </div>
                    </div>
                    <div class="taa-blurb--copy">
                        <p class="taa-blurb--copy-large">OPTICS consumes AIS (automatic information system) radio transponder data from the hundreds of vessels in the Port area to display 3D models and information. Tankers, Tractor Tugs, Push Tugs, Bulk Cargo, Vehicle Carriers, Dredgers, Pleasure, Sailing Vessels, Fishing, Coast Guard, Police, and Fire vessel details can be displayed in a list view that can be sorted and filtered and/or by selecting an individual vessel. 200 - 300 vessels in the Port area including</p>
                    </div>
                </div>
            </div>
            
            <div class="taa-section taa-slider">
                <div class="taa-container">

                    <Splide options="options1" class="splide">
                        <SplideSlide>
                          <img src="img/optics-vessels-1.jpg" >
                        </SplideSlide>
                        <SplideSlide>
                          <img src="img/optics-vessels-2.jpg" >
                        </SplideSlide>
                        <SplideSlide>
                          <img src="img/optics-vessels-3.jpg" >
                        </SplideSlide>
                        <SplideSlide>
                          <img src="img/optics-vessels-4.jpg" >
                        </SplideSlide>
                        <SplideSlide>
                          <img src="img/optics-vessels-5.jpg" >
                        </SplideSlide>
                    </Splide>

                </div>
            </div>
            
            <div class="taa-space"></div>
            
            <div class="taa-section taa-slider">
                <div class="taa-container">

                    <Splide :options="options4" class="splide" >
                        <SplideSlide>
                          <img src="img/optics-vessels-10.png" >
                        </SplideSlide>
                        <SplideSlide>
                          <img src="img/optics-vessels-11.png" >
                        </SplideSlide>
                        <SplideSlide>
                          <img src="img/optics-vessels-12.png" >
                        </SplideSlide>
                        <SplideSlide>
                          <img src="img/optics-vessels-13.png" >
                        </SplideSlide>
                        <SplideSlide>
                          <img src="img/optics-vessels-13.png" >
                        </SplideSlide>
                        <SplideSlide>
                          <img src="img/optics-vessels-13.png" >
                        </SplideSlide>
                        <SplideSlide>
                          <img src="img/optics-vessels-14.png" >
                        </SplideSlide>
                        <SplideSlide>
                          <img src="img/optics-vessels-15.png" >
                        </SplideSlide>
                        <SplideSlide>
                          <img src="img/optics-vessels-16.png" >
                        </SplideSlide>
                        <SplideSlide>
                          <img src="img/optics-vessels-17.png" >
                        </SplideSlide>
                        <SplideSlide>
                          <img src="img/optics-vessels-18.png" >
                        </SplideSlide>
                        <SplideSlide>
                          <img src="img/optics-vessels-19.png" >
                        </SplideSlide>
                        <SplideSlide>
                          <img src="img/optics-vessels-19.png" >
                        </SplideSlide>
                        <SplideSlide>
                          <img src="img/optics-vessels-20.png" >
                        </SplideSlide>
                        <SplideSlide>
                          <img src="img/optics-vessels-21.png" >
                        </SplideSlide>
                    </Splide>

                </div>
            </div>
            
            <div class="taa-section taa-blurb full-width group-below">
                <div class="taa-container">
                    <div class="taa-blurb--subhead">Security Incidents and Units</div>
                    <div class="taa-icons">
                        <div class="taa-icon">
                            <img class="taa-icon--img" src="img/optics-security-security.png" />
                            <span class="taa-icon--label">Security</span>
                        </div>
                        <div class="taa-icon">
                            <img class="taa-icon--img" src="img/optics-security-security-2.png" />
                            <span class="taa-icon--label">Security</span>
                        </div>
                        <div class="taa-icon">
                            <img class="taa-icon--img" src="img/optics-security-default.png" />
                            <span class="taa-icon--label">Default</span>
                        </div>
                        <div class="taa-icon">
                            <img class="taa-icon--img" src="img/optics-security-oilspill.png" />
                            <span class="taa-icon--label">Oil Spill</span>
                        </div>
                        <div class="taa-icon">
                            <img class="taa-icon--img" src="img/optics-security-colision.png" />
                            <span class="taa-icon--label">Collision</span>
                        </div>
                        <div class="taa-icon">
                            <img class="taa-icon--img" src="img/optics-security-security-3.png" />
                            <span class="taa-icon--label">Security</span>
                        </div>
                        <div class="taa-icon">
                            <img class="taa-icon--img" src="img/optics-security-fire.png" />
                            <span class="taa-icon--label">Fire</span>
                        </div>
                    </div>
                    <div class="taa-blurb--copy">
                        <p class="taa-blurb--copy-large">OPTICS displays CJIS (Criminal Justice Information Services) Security Compliant Computer Aided Dispatch data entered by Port Police into their Dispatch Tool including locations and status of active security incidents (Spills, Collisions, Trespassers, Fires, etc) and security units (vehicles and vessels). OPTICS adds additional data by providing a radius around the incident and providing all nearby vessels, facilities and units within that perimeter to aid in security assessment. The details for the incidents can be displayed in a list view that can be sorted and filtered and/or by selecting an individual incident.</p>
                        <p>NOTE: Displayed Computer Aided Dispatch data and  security locations (gates and cameras) is synthetic to comply with CJIS requirements</p>
                    </div>
                </div>
            </div>
            
            <div class="taa-section taa-slider">
                <div class="taa-container">


                    <Splide class="splide" :options='options1'>
                        <SplideSlide>
                          <img src="img/optics-security-1.jpg" >
                        </SplideSlide>
                        <SplideSlide>
                          <img src="img/optics-security-2.jpg" >
                        </SplideSlide>
                        <SplideSlide>
                          <img src="img/optics-security-3.jpg" >
                        </SplideSlide>
                    </Splide>

                </div>
            </div>
            
            <div class="taa-space"></div>
            
            <div class="taa-section taa-slider">
                <div class="taa-container">

                    <Splide class="splide" :options='options4' aria-label="My Favorite Images">
                        <SplideSlide>
                          <img src="img/optics-security-10.png" >
                        </SplideSlide>
                        <SplideSlide>
                          <img src="img/optics-security-11.png" >
                        </SplideSlide>
                        <SplideSlide>
                          <img src="img/optics-security-12.png" >
                        </SplideSlide>
                        <SplideSlide>
                          <img src="img/optics-security-13.png" >
                        </SplideSlide>
                    </Splide>
                </div>
            </div>
            
            <div class="taa-section taa-blurb full-width group-below">
                <div class="taa-container">
                    <div class="taa-blurb--subhead">Other Data: Weather / Ocean Data, MARSEC</div>
                    <div class="taa-blurb--copy">
                        <p class="taa-blurb--copy-large">Current weather conditions, weather forecasts, and sensor data from area Aids to Navigation (water temperature, salinity, windspeed, water level, barometer, etc) is viewable in the application. MARSEC, the Coast Guard’s three-tiered system of Maritime Security is also displayed.</p>
                    </div>
                </div>
            </div>
            
            <div class="taa-section taa-screen">
                <div class="taa-container">
                    <div class="taa-screen--inner">
                        <div class="taa-screen--image"><img class="taa-screen--src" src="img/optics-weather.jpg" alt=""></div>
                    </div>
                </div>
            </div>
            
            <div class="taa-section taa-blurb full-width">
                <div class="taa-container">
                    <div class="taa-blurb--inner">
                        <div class="taa-blurb--copy">
                            <p>OPTICS is a custom solution built with <a href="/">The Acceleration Agency</a>’s <a href="/#/project-gemini">Project Gemini</a> Active Digital Twin platform.</p>
                            <a href="#" class="taa-link">
                                <div class="taa-link--action">
                                    <router-link class="taa-link--copy" to="#contact" v-scroll-to="'#contact'">Contact us for a demo</router-link>
                                    <svg class="taa-link--icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.16 9.16" data-v-8fbe9336="">
                                        <path fill="currentColor" d="M1,9.16a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42L5.75,2H1.8a1,1,0,0,1,0-2H8.16a1.22,1.22,0,0,1,.38.07h0a1.26,1.26,0,0,1,.32.21h0a.91.91,0,0,1,.2.32A.92.92,0,0,1,9.16,1V7.36a1,1,0,1,1-2,0V3.41L1.71,8.87A1,1,0,0,1,1,9.16Z" data-v-8fbe9336=""></path>
                                    </svg>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>


</template>

<script>

    import { LocalStorageKeys } from '@/libs/const.js'
    import ls from 'local-storage';
    import site from './site.json';

    import { ImgComparisonSlider } from '@img-comparison-slider/vue';

    import { Splide, SplideSlide } from '@splidejs/vue-splide';
    
    import '@splidejs/vue-splide/dist/css/splide.min.css';

/*
    Splide.options = {
      type   : 'loop',
      perPage: 1,
      gap: '20px',
      pagination: false,
      arrowPath: 'M14,33c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4L24.6,20L13.3,8.7c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0L27.4,20L14.7,32.7C14.5,32.9,14.3,33,14,33z'
    };


    //import VueSplide from '@splidejs/vue-splide';

*/

    //console.log('VueSplide', VueSplide);


    export default {
        name: 'Section',
        components: {Splide, SplideSlide, ImgComparisonSlider},
        data: function () {
            return {
                isFirstTimeOnSite: 0,
                info: null,
              playerOptions: {
                  // videojs options
                autoplay: true,
                controls: false,
                controlBar: {
                    timeDivider: false,
                    durationDisplay: false
                },
                muted: true,
                loop: true,
                language: 'en',
                playbackRates: [1.0],
                sources: [{
                    type: "video/mp4",
                    src: "/img/optics-hero-1080.mp4"
                }],
                poster: "/img/optics-hero.png",
                width: 1280,
                aspectRatio: '16:9',
                }
            }
        },
        watch: {
            $route() {
                this.info = site['sections'][this.$route.name]
                setTimeout(() => {
                    window.scrollTo(0, 0);
                }, 1)
            }
        },


        mounted() {
            window.scrollTo(0, 0);

        },
        created() {

            this.info = site['sections'][this.$route.name]

            this.isFirstTimeOnSite = ls(LocalStorageKeys.FirstTimeOnSite);
            if (this.isFirstTimeOnSite === null || this.isFirstTimeOnSite === false) {
                ls(LocalStorageKeys.FirstTimeOnSite, false);
            } else {
                this.isFirstTimeOnSite = true;
            }
        },


        setup() {
            const options =  {
                type   : 'loop',
                perPage: 6,
                gap: '20px',
                pagination: false,
                arrowPath: 'M14,33c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4L24.6,20L13.3,8.7c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0L27.4,20L14.7,32.7C14.5,32.9,14.3,33,14,33z'
            } ;

            const options1 =  {
                type   : 'loop',
                perPage: 1,
                gap: '20px',
                pagination: false,
                arrowPath: 'M14,33c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4L24.6,20L13.3,8.7c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0L27.4,20L14.7,32.7C14.5,32.9,14.3,33,14,33z'
            } ;

            const options3 = {
                type   : 'loop',
                perPage: 3,
                gap: '20px',
                pagination: false,
                breakpoints: {
                    "1200": {
                        perPage: 2
                    },
                    "800": {
                        perPage:1
                    }
                },
                arrowPath: 'M14,33c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4L24.6,20L13.3,8.7c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0L27.4,20L14.7,32.7C14.5,32.9,14.3,33,14,33z'
            } ;

            const options4 =  {
                type   : 'loop',
                perPage: 4,
                gap: '20px',
                pagination: false,
                breakpoints: {
                    "1200": {
                        perPage: 3
                    },
                    "800": {
                        perPage:2
                    }
                },         
                arrowPath: 'M14,33c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4L24.6,20L13.3,8.7c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0L27.4,20L14.7,32.7C14.5,32.9,14.3,33,14,33z'
            } ;



            return { options, options1, options3, options4 };
        }


    }




</script>

<style>


               .taa-blurb--logo {
                    margin-bottom: 10px;
                }

                .taa-blurb--logo img {
                    max-width: 100%;
                }

                .full-width .taa-blurb--inner {
                    max-width: unset;
                }

                .taa-blurb--copy a:not(.taa-link) {
                    color: inherit;
                    text-decoration: underline;
                }

                .taa-logo-line {
                    display: flex;
                    align-items: center;
                    gap: 40px 80px;
                    flex-wrap: wrap;
                }

                .taa-cols {
                    display: flex;
                    gap: 100px;
                    align-items: flex-start;
                }

                .taa-col {
                    flex: 1;
                }

                .taa-blurb--list {
                    padding: 0 0 0 12px;
                    margin: 16px;
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    font-size: 14px;
                }

                img-comparison-slider {
                    --divider-width: 2px;
                    max-width: 100%;
                }

                img-comparison-slider:focus {
                    outline: none;
                }

                img-comparison-slider img {
                    width: 100%;
                }

                .taa-compare--handle {
                    width: 8px;
                    height: 68px;
                    background: #fff;
                    border-radius: 8px;
                }

                .taa-compare .taa-container {
                    align-items: center;
                }

                .splide__slide img {
                    width: 100%;
                }

                .splide__arrow {
                    background: unset;
                }

                .splide__arrow svg {
                    width: unset;
                    height: unset;
                }

                .splide__arrow--prev {
                    left: -40px;
                }

                .splide__arrow--next {
                    right: -40px;
                }

                .group-below {
                    padding-bottom: 0;
                }

                .taa-icons {
                    display: flex;
                    gap: 20px;
                    margin-bottom: 32px;
                    flex-wrap: wrap;
                }

                .taa-icon {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                }

                .taa-icon--img {
                    width: 36px;
                    height: 44px;
                    object-fit: contain;
                }

                .taa-icon--label {
                    font-size: 14px;
                    white-space: nowrap;
                }

                @media only screen and (max-width: 1023px) {
                    .taa-cols {
                        flex-direction: column;
                        gap: 40px;
                    }
                }

                @media only screen and (max-width: 767px) {
                    .taa-section.taa-slider {
                        padding-left: 48px;
                        padding-right: 48px;
                    }
                }
                




h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

.taa-caption {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 0 150px;
    font-size: .85em;
}

.taa-full-width {
    width: 100%;
    position: relative;
}

.taa-roboflow-arrow {
    position: absolute;
    height: 100px;
    left: 47px;
}

@media only screen and (max-width: 768px) {
    .taa-caption {
        padding: 0 30px;
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: .7em;
        line-height: 1.3em;
    }
    .taa-roboflow-arrow {
        height: 65px;
        left: 20px;
    }
}

</style>
