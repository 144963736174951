<template>


    <div class="taa-section taa-statement">
        <div class="taa-container">


            <div class="taa-statement--inner">
                <div v-html="info.index.tagline" class="taa-statement--headline" />
                <div class="taa-statement--copy">
                    <div class="taa-statement--subhead" v-html="info.index.subhead" />
                    <div v-html="info.index.content" />
                    <router-link class="taa-link" to="/" v-scroll-to="'#about'">
                        <div class="taa-link--action">
                            <div class="taa-link--copy">About Us</div>
                            <svg class="taa-link--icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.16 9.16"><path fill="currentColor" d="M1,9.16a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42L5.75,2H1.8a1,1,0,0,1,0-2H8.16a1.22,1.22,0,0,1,.38.07h0a1.26,1.26,0,0,1,.32.21h0a.91.91,0,0,1,.2.32A.92.92,0,0,1,9.16,1V7.36a1,1,0,1,1-2,0V3.41L1.71,8.87A1,1,0,0,1,1,9.16Z"></path></svg>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>


        <div class="taa-image">
            <img class="taa-image--src" src="img/experience-hero.jpg" alt="">
        </div>
        
        <div class="taa-section taa-blurb">
            <div class="taa-container">
                <div class="taa-blurb--inner">
                    <div class="taa-blurb--headline">
                        Experience Orchestration
                    </div>
                    <div class="taa-blurb--subhead">
                        Customer experience matters.
                    </div>
                    <div class="taa-blurb--copy">
                        <p>
                            Experience Orchestration (XO) facilitates the next level of customer experiences. XO blends the latest thinking of user-centric-design, modern application platforms, rich real-time visualizations with contextual operator actions, using proprietary tools and processes.
                        </p>
                        <p>
                            The Acceleration Agency’s signature technology tools, processes, knowledge, and expertise effectively allow companies to switch from historical-backward-looking experience design to real-time active management of a customer's experience to deliver personalized experiences for each user.
                        </p>
                        <router-link class="taa-link" to="/experience">
                            <div class="taa-link--action">
                                <div class="taa-link--copy">See our work</div>
                                <svg class="taa-link--icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.16 9.16"><path fill="currentColor" d="M1,9.16a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42L5.75,2H1.8a1,1,0,0,1,0-2H8.16a1.22,1.22,0,0,1,.38.07h0a1.26,1.26,0,0,1,.32.21h0a.91.91,0,0,1,.2.32A.92.92,0,0,1,9.16,1V7.36a1,1,0,1,1-2,0V3.41L1.71,8.87A1,1,0,0,1,1,9.16Z"></path></svg>
                            </div>
                            <div class="taa-link--extra">Live and online game experiences</div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="taa-space"></div>
        
        <div class="taa-image">
            <img class="taa-image--src" src="img/xiot-hero.jpg" alt="">
        </div>
        
        <div class="taa-section taa-blurb">
            <div class="taa-container">
                <div class="taa-blurb--inner">
                    <div class="taa-blurb--headline">
                        Experience Internet of Things (xIoT)
                    </div>
                    <div class="taa-blurb--subhead">
                        xIOT: Using Data to Provide Insights to Drive Action
                    </div>
                    <div class="taa-blurb--copy">
                        <p>
                            eXperiential Internet of Things provides real-time location intelligence to drive expected experiences for the experience generation, product development, and other inter-connected experiences. This creates the foundation for visualization and personalization at scale; seeing and acting in real-time produces the blend of hardware-driven and software-driven magic.
                        </p>
                        <p>
                            We continually explore, test and push the art-of-the-possible with the infrastructure required for the merging of the physical and the digital spaces for our clients.
                        </p>
                        <router-link class="taa-link" to="/xiot">
                            <div class="taa-link--action">
                                <div class="taa-link--copy">See our work</div>
                                <svg class="taa-link--icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.16 9.16"><path fill="currentColor" d="M1,9.16a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42L5.75,2H1.8a1,1,0,0,1,0-2H8.16a1.22,1.22,0,0,1,.38.07h0a1.26,1.26,0,0,1,.32.21h0a.91.91,0,0,1,.2.32A.92.92,0,0,1,9.16,1V7.36a1,1,0,1,1-2,0V3.41L1.71,8.87A1,1,0,0,1,1,9.16Z"></path></svg>
                            </div>
                            <div class="taa-link--extra">Interconnected Experiences</div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="taa-space"></div>
        
        <div class="taa-image">
            <img class="taa-image--src" src="img/development-hero-expanded.png" alt="">
        </div>
        
        <div class="taa-section taa-blurb">
            <div class="taa-container">
                <div class="taa-blurb--inner">
                    <div class="taa-blurb--headline">
                        End to End Product Development
                    </div>
                    <div class="taa-blurb--subhead">
                        Providing Turnkey Foundations for Magical Experiences.
                    </div>
                    <div class="taa-blurb--copy">
                        <p>
                            We assist our customers in transforming their product ideas into robust, innovative and ready-to-market realities. TAA specializes in building systems through a unique lens.  Not only do we deliver iterations as quickly as possible, we retain flexibility to foster emergent user behaviors incorporating paths to sustainability and future integrations with other Client systems.
                        </p>
                        <router-link class="taa-link" to="/development">
                            <div class="taa-link--action">
                                <div class="taa-link--copy">See our work</div>
                                <svg class="taa-link--icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.16 9.16"><path fill="currentColor" d="M1,9.16a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42L5.75,2H1.8a1,1,0,0,1,0-2H8.16a1.22,1.22,0,0,1,.38.07h0a1.26,1.26,0,0,1,.32.21h0a.91.91,0,0,1,.2.32A.92.92,0,0,1,9.16,1V7.36a1,1,0,1,1-2,0V3.41L1.71,8.87A1,1,0,0,1,1,9.16Z"></path></svg>
                            </div>
                            <div class="taa-link--extra">Product Development</div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="taa-space"></div>
        
        <div class="taa-image">
            <img class="taa-image--src" src="img/applications-hero.jpg" alt="">
        </div>
        
        <div class="taa-section taa-blurb">
            <div class="taa-container">
                <div class="taa-blurb--inner">
                    <div class="taa-blurb--headline">
                        Applications &amp; Platforms
                    </div>
                    <div class="taa-blurb--subhead">
                        Comprehensive B2B, B2C, and enterprise full-stack solutions that operate at scale.
                    </div>
                    <div class="taa-blurb--copy">
                        <p>
                            TAA employs the most advanced technologies, design and development techniques to create full-stack enterprise solutions (B2B and B2C) that operate at scale . We conceptualize, design, build, deploy and monitor our comprehensive technology solutions to solve our customer’s unique and most demanding needs.
                        </p>
                        <router-link class="taa-link" to="/applications">
                            <div class="taa-link--action">
                                <div class="taa-link--copy">See our work</div>
                                <svg class="taa-link--icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.16 9.16"><path fill="currentColor" d="M1,9.16a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42L5.75,2H1.8a1,1,0,0,1,0-2H8.16a1.22,1.22,0,0,1,.38.07h0a1.26,1.26,0,0,1,.32.21h0a.91.91,0,0,1,.2.32A.92.92,0,0,1,9.16,1V7.36a1,1,0,1,1-2,0V3.41L1.71,8.87A1,1,0,0,1,1,9.16Z"></path></svg>
                            </div>
                            <div class="taa-link--extra">Applications &amp; Platforms Development</div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="taa-space"></div>
        
        <div class="taa-section taa-logos">
            <div class="taa-container">
                <div class="taa-logos--inner">

                    <img src="/img/client-logos.png" class="taa-image--src">

                    <!--
                    <div class="taa-logo&#45;&#45;wrap">
                        <img class="taa-logos&#45;&#45;logo" src="img/logo&#45;&#45;bbc.png" alt="">
                    </div>
                    <div class="taa-logo&#45;&#45;wrap">
                        <img class="taa-logos&#45;&#45;logo" src="img/logo&#45;&#45;penn-national.png" alt="">
                    </div>
                    <div class="taa-logo&#45;&#45;wrap">
                        <img class="taa-logos&#45;&#45;logo" src="img/logo&#45;&#45;disney.png" alt="">
                    </div>
                    <div class="taa-logo&#45;&#45;wrap">
                        <img class="taa-logos&#45;&#45;logo" src="img/logo&#45;&#45;secret-cinema.png" alt="">
                    </div>
                    <div class="taa-logo&#45;&#45;wrap">
                        <img class="taa-logos&#45;&#45;logo" src="img/logo&#45;&#45;secret-cinema.png" alt="">
                    </div>
                    <div class="taa-logo&#45;&#45;wrap">
                        <img class="taa-logos&#45;&#45;logo" src="img/logo&#45;&#45;disney.png" alt="">
                    </div>
                    <div class="taa-logo&#45;&#45;wrap">
                        <img class="taa-logos&#45;&#45;logo" src="img/logo&#45;&#45;carnival.png" alt="">
                    </div>
                    <div class="taa-logo&#45;&#45;wrap">
                        <img class="taa-logos&#45;&#45;logo" src="img/logo&#45;&#45;college-forward.png" alt="">
                    </div>
                    -->
                </div>
            </div>
        </div>
        
        <div class="taa-space"></div>
        
        <div class="taa-image">
            <img class="taa-image--src" src="img/gemini-hero.jpg" alt="">
        </div>
        
        <div class="taa-section taa-blurb">
            <div class="taa-container">
                <div class="taa-blurb--inner">
                    <div class="taa-blurb--headline">
                        TAA Studios
                    </div>
                    <div class="taa-blurb--subhead">
                        Innovation through Internal Development
                    </div>
                    <div class="taa-blurb--copy">
                        <p>
                            TAA Studios is where The Acceleration Agency internally develops projects to explore cutting edge innovation across a wide variety of industries including virtual reality, marketplaces, Web3, and Digital Twins. We then leverage that experience to serve our clients.
                        </p>
                        <router-link class="taa-link" to="/taa-studios">
                            <div class="taa-link--action">
                                <div class="taa-link--copy">See TAA Studios projects</div>
                                <svg class="taa-link--icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.16 9.16"><path fill="currentColor" d="M1,9.16a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42L5.75,2H1.8a1,1,0,0,1,0-2H8.16a1.22,1.22,0,0,1,.38.07h0a1.26,1.26,0,0,1,.32.21h0a.91.91,0,0,1,.2.32A.92.92,0,0,1,9.16,1V7.36a1,1,0,1,1-2,0V3.41L1.71,8.87A1,1,0,0,1,1,9.16Z"></path></svg>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="taa-section taa-subhead" id="about">
            <div class="taa-container">
                <div class="taa-subhead--inner">
                    <svg class="taa-slash" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 18"><path fill="currentColor" d="M6.31619 17.9998L0 0L9.52546 0.000199749L16 18L6.31619 17.9998Z"></path></svg>
                    <div class="taa-subhead--copy">Who we are</div>
                </div>
            </div>
        </div>
        
        <div class="taa-section taa-split">
            <div class="taa-split--inner">
                <div class="taa-split--content">
                    <div class="taa-split--align">
                        <div class="taa-blurb--headline">
                            A Talented Tribe of Seasoned Makers and Visionaries.
                        </div>
                        <div class="taa-blurb--copy">
                            <p>
                                Our common bond is our passion for innovation and our deep commitment to improving the experiences of our clients and their customers.
                            </p>
                            <a class="taa-link" href="#/about">
                                <div class="taa-link--action">
                                    <div class="taa-link--copy">Meet the team</div>
                                    <svg class="taa-link--icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.16 9.16"><path fill="currentColor" d="M1,9.16a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42L5.75,2H1.8a1,1,0,0,1,0-2H8.16a1.22,1.22,0,0,1,.38.07h0a1.26,1.26,0,0,1,.32.21h0a.91.91,0,0,1,.2.32A.92.92,0,0,1,9.16,1V7.36a1,1,0,1,1-2,0V3.41L1.71,8.87A1,1,0,0,1,1,9.16Z"/></svg>
                                </div>
                            </a>
                        </div>
                        <div class="taa-blurb--quote">
                            <p>
                                “Although I am a technologist, what we at TAA build is not about technology. It's about the experience, it's about the operational capabilities, the value that we produce for our Clients. Technology is an accelerant to what we do.”
                            </p>
                        </div>
                        <div class="taa-blurb-attribution">
                            - Rodney White
                        </div>
                    </div>
                </div>
                <div class="taa-split--photo">
                    <img class="taa-split--photo-src" src="img/team.jpg" alt="">
                </div>
            </div>
        </div>
    

</template>

<script>

import {LocalStorageKeys} from '@/libs/const.js'
import ls from 'local-storage';
import site from './site.json';


export default {
  name: 'Home',
  components: {
  },
  data: function () {
    return {
      isFirstTimeOnSite: 0,
      info: site
    }
  },
  mounted() {
      window.scrollTo(0, 0);
  },
  created() {
    this.isFirstTimeOnSite = ls(LocalStorageKeys.FirstTimeOnSite);
    console.log('isFirstTimeOnSite', this.isFirstTimeOnSite);
    if (this.isFirstTimeOnSite === null) {
      ls(LocalStorageKeys.FirstTimeOnSite, false);
      this.isFirstTimeOnSite = false;
    } else {
      this.isFirstTimeOnSite = true;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>
