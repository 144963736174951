import { createApp } from 'vue'
import App from './App.vue'
import router from './router' 
import Particles from "particles.vue3";
import VueScrollTo from 'vue-scrollto'
import VideoPlayer from 'vue-videojs7'
import VueGtag from "vue-gtag-next";

//import '@splidejs/vue-splide/css';

import 'video.js/dist/video-js.css'


import VueVideoPlayer from '@videojs-player/vue'
import 'video.js/dist/video-js.css'



createApp(App).use(router).use(Particles).use(VideoPlayer).use(VueVideoPlayer).use(VueScrollTo).use(VueGtag, {
      property: {
      id: "G-VRLRWXVZK3",
      useDebugger: true
    }
}).mount('#taa')
