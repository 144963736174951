<template>
  <div v-if='showUKR'>
    <UKR />
  </div>
  <Term />
  <Nav />
  <router-view />
  <Footer />
</template>

<script>
import Term from '@/components/term.vue'
import Nav from '@/components/nav.vue'
import Footer from '@/components/Footer.vue'
import UKR from '@/components/ukr.vue'

import {LocalStorageKeys} from '@/libs/const.js'
import ls from 'local-storage';


export default {
  name: 'App',
  data: function () {
    return {
      showUKR: true
    }
  },
  components: {
    UKR,
    Term,
    Nav,
    Footer
  },

  mounted() {

      this.showUKR = ls(LocalStorageKeys.ShowUKR);
      console.log('ShowUKR', this.ShowUKR);
      if (this.showUKR === null) {
        ls(LocalStorageKeys.ShowUKR, false);
        this.showUKR = true;
  
        setTimeout(() => {
          this.showUKR = false;
        }, 5000)

      } else {
        this.showUKR = false;
      }



    }

}
</script>

<style>

</style>
