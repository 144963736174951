<template>
    <div v-if='showTerminal' ref='term' class="taa_terminal">
    </div>
</template>

<script>

import {KeyCodes} from '@/libs/const.js'

import 'xterm/css/xterm.css'
import { Terminal } from 'xterm'
import { FitAddon } from 'xterm-addon-fit';

import parser  from 'bash-parser';

//import { WebLinksAddon } from 'xterm-addon-web-links';

const fitAddon = new FitAddon();
//const webLinksAddon = new WebLinksAddon();


export default {
  name: 'terminal',

   data: function () {
    return {
      showTerminal: 0,
      lastBufferUpdate: 0,
      buffer: [],
      lineBuffer: '',
      BUFFER_DELAY: 700,
      CHEAT_CODE: [KeyCodes.Up, KeyCodes.Up, KeyCodes.Down, KeyCodes.Down, KeyCodes.Left,
            KeyCodes.Right, KeyCodes.Left, KeyCodes.Right],
    }
  },
  created() {
    window.onresize = () => {
      console.log('fit');
      this.fit()
    };
    document.onkeydown = (event) => {
      this.addToBuffer(event.keyCode);
      //event.preventDefault();
    }
  },
  unmounted() {
    window.onresize = null;
    document.onkeypress = null;
  },

  mounted () {

  },
  beforeUnmount () {
    this.$terminal.selectAll()
    this.$emit('update-buffer', this.$terminal.getSelection().trim())
    //this.$terminal.destroy()
  },

  methods: {
    fit () {
      if (this.showTerminal) {
        this.$refs.term.style.height = window.innerHeight+"px";
        fitAddon.fit();
      }
    },
    focus () {
      this.$terminal.focus()
    },
    blur () {
      this.$terminal.blur()
    },


    addToBuffer(keyCode) {
        // if it has been more than BUFFER_DELAY milliseconds, reset the buffer
        if (this.lastBufferUpdate + this.BUFFER_DELAY < Date.now()) {
            this.buffer = [];
        }
        this.lastBufferUpdate = Date.now();
        this.buffer.push(keyCode);
        
        if (this.buffer.length === this.CHEAT_CODE.length && JSON.stringify(this.buffer) === JSON.stringify(this.CHEAT_CODE)) {
            this.enableTerminal();
        }
    },

    enableTerminal  () {
      this.showTerminal = true;
      this.$nextTick( () => {
        let term = new Terminal(
          {
              theme: {
                foreground: "#EEEEEE",
                background: "#000000CC",
                cursor: "#CFF5DB"
              },
              allowTransparency: true
          }
        )
        term.loadAddon(fitAddon)
        //term.loadAddon(webLinksAddon)

        term.open(this.$refs.term, true)
        this.fit();
        term.focus();

        //term.on('blur', () => this.$emit('blur'))
        //term.on('focus', () => this.$emit('focus'))
        term.onResize(size => {
          if (size.cols !== this.cols) this.$emit('update-cols', size.cols)
          if (size.rows !== this.rows) this.$emit('update-rows', size.rows)
        })

        this.$terminal = term
        this.startTerminal();
      })
    },


    processLine () {

      let line = this.lineBuffer
      let argv = line.split(" ")
      //let argc = argv.length;

      let parsedLine = parser(line);
      console.log(parsedLine);

      var cmd = argv[0];
      
      if (cmd === 'exit'){
        this.showTerminal = false;
      } else if (cmd === 'help') {
        this.$terminal.writeln('');
        this.$terminal.writeln('Available Commands:');        
        this.$terminal.writeln('  help: Shows this help text');        
        this.$terminal.writeln('  reload: Reloads the current page');        
        this.$terminal.writeln('  exit: Closes the Development Console');        
      } else if (cmd === 'reload') {
        location.reload();
      } else {
        this.$terminal.writeln('\n\n'+ cmd + ' unknown command, try help');
        //this.$terminal.writeln('\n'+ JSON.stringify(parsedLine, null, 2));
      }

    },

    startTerminal() {
      if (this.$terminal._initialized) {
        return;
      }

      this.$terminal._initialized = true;
  
      this.$terminal.prompt = () => {
        this.$terminal.write('\r\n$ ');
      };

      this.$terminal.writeln('Welcome to The TAA Development Console');
      this.$terminal.writeln('  type \'help\' if you don\'t know what to do.');
      this.$terminal.writeln('');
      this.$terminal.prompt();

      window.term = this.$terminal

      this.$terminal.onKey((e) => {
        const ev = e.domEvent;
        const printable = !ev.altKey && !ev.ctrlKey && !ev.metaKey;

        if (ev.keyCode === 13) {
          this.processLine();
          this.lineBuffer = '';
          this.$terminal.prompt();
        } else if (ev.keyCode === 8) {
          this.lineBuffer = this.lineBuffer.slice(0, -1);
          // Do not delete the prompt
          if (this.$terminal._core.buffer.x > 2) {
            this.$terminal.write('\b \b');
          }
        } else if (printable) {
          this.$terminal.write(e.key);
          if (e.domEvent.keyCode >= 32 && e.domEvent.keyCode <= 90 ) {
            this.lineBuffer = this.lineBuffer + e.key;
          }  
        }

      })
    }

  }

}









</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.taa_terminal {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 500px;
  z-index: 999;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
