<template>

    <div ref='taa-header' class="taa-section taa-header " v-bind:class="{'taa-header-hero-scrolled': logoSmall, 'taa-header-hero': !hideTAA }">
          <div ref='taa-container' class="taa-container">                
              <div ref='taa-header--inner' class="taa-header--inner">
                  <a href="/"><svg  ref='taa-logo' class="taa-logo taa-logo-visible" v-bind:class="{'taa-logo-small': logoSmall, 'taa-logo-red': logoSmall }" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40"><path fill="currentColor" d="M16.56,13.85l-2.22,6.78h4.48l-2.23-6.78Z"></path><path fill="currentColor" d="M20,0A20,20,0,1,0,40,20,20,20,0,0,0,20,0Zm.94,27.12-1.2-3.64H13.37l-1.14,3.64H8.47L14.84,9.64H18.5l6.27,17.48Zm6.42,0L21.24,9.64h3.67l6.27,17.48Z"></path></svg></a>

                  <div v-if='!hideTAA' style="background-image: url(&quot;img/gigstan.jpg&quot;); width: 0px;" data-slick-index="-1" id="" aria-hidden="true" tabindex="-1" class="taa-ident">The Acceleration Agency
                  </div>
                          
                  <div  @click='logoClicked' class="taa-nav-toggle" v-bind:class="{'taa-nav-toggle-active': navToggle}">
                      <svg class="taa-nav-toggle--icon taa-nav-toggle--bars" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path></svg>
                      <svg class="taa-nav-toggle--icon taa-nav-toggle--times" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"></path></svg>
                  </div>

                  <div class="taa-nav" v-bind:class="{'taa-nav-active': navToggle}">
                      <div class="taa-nav--item">
                        <router-link class="taa-nav--link" to="/About" >About</router-link>
                      </div>
                      <div class="taa-nav--item">
                        <router-link class="taa-nav--link" to="/Experience">Work</router-link>
                        <div class="taa-subnav">
                            <router-link class="taa-subnav--item" to="/optics">Optics: Active Digital Twin</router-link>
                            <router-link class="taa-subnav--item" to="/experience">Experience Orchestration</router-link>
                            <router-link class="taa-subnav--item" to="/xiot">Experience Internet of Things</router-link>
                            <router-link class="taa-subnav--item" to="/development">End to End Product Development</router-link>
                            <router-link class="taa-subnav--item" to="/applications">Applications & Platforms</router-link>
                        </div>
                      </div>
                      <div class="taa-nav--item">
                        <router-link class="taa-nav--link" to="/taa-studios">TAA Studios</router-link>
                        <div class="taa-subnav">
                            <router-link class="taa-subnav--item" to="project-gemini">Project Gemini</router-link>
<!--                            <router-link class="taa-subnav&#45;&#45;item" to="roboflow">Roboflow Exporter Extension for Omniverse</router-link>-->
                        </div>
                      </div>
                      <div class="taa-nav--item">
                        <router-link class="taa-nav--link" to="#" v-scroll-to="'#contact'">Contact</router-link>
                      </div>
                  </div>                
              </div>
          </div>
    </div>

</template>

<script>
//import VideoBackground from 'vue-responsive-video-background-player'

export default {
  name: 'Nav',
  components: {
      
  },
  data: function () {
    return {
      navToggle: false,
      logoSmall: false,
      hideTAA: false,
      heroHeight: 100,
      showVideo:0,
      logoLeft:0,
      scroll:0
    }
  },
  methods: {
    logoClicked: function () {
        this.navToggle = !this.navToggle;
    },
    handleResize () {
        let header = this.$refs['taa-header'];
        let container = this.$refs['taa-container'];

        window.taaContainer = container;
        window.taaHeader = header;

        let logo = this.$refs['taa-logo']

        if (this.$route.path === '/') {
          logo.style.left = container.offsetLeft;
        } else {
          logo.style.left = logo.style.left = window.getComputedStyle(container).paddingLeft;
        }

        this.logoLeft = logo.style.left;

        if (this.showVideo) {
          this.$refs.videoBackground.style.height = window.innerHeight / 1.5 + "px";
        }

    },
    handleScroll () {
      this.scroll = window.pageYOffset;
      if (this.scroll > 55) {
          this.logoSmall = true;
      } else {
          this.logoSmall = false;
      }

      if (this.$route.path === '/') {
        this.hideTAA = false;
      } else {
        this.hideTAA = true;
      }
    }
  },
  watch:{
    $route (){
      this.handleResize();
      this.handleScroll();
      this.navToggle = false;
    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize);
  },
  ummounted () {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  },
  mounted() {

    this.heroHeight = this.$refs['taa-header'].clientHeight
    this.handleResize();
    this.handleScroll();

/*
    setTimeout(() => {
      console.log('showing video', window.innerHeight)
      this.showVideo = 1;
      this.$nextTick ( () => {
        this.$refs.videoBackground.style.height = window.innerHeight / 1.5 + "px";
      })
    }, 1000)
*/

  }
}




</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>
