<template>

    <div class="taa-section taa-heading" style="padding-top: 84px">
        <div class="taa-container">
            <img src="/img/gemini-logo.png" style="height: 124px; width: 124px; margin-bottom: 46px">
            <div class="taa-heading--inner">
                <div class="taa-heading--copy">Project Gemini</div>
            </div>
        </div>
    </div>

    <!-- real time active digital twin features & tools -->

    <div class="taa-section">
        <div class="taa-container">
            <div class="taa-project--inner">
                <div class="taa-project--brief">
                    <div class="taa-blurb--headline">
                        Real Time Active Digital Twin
                    </div>
                    <div class="taa-blurb--copy">
                        <p>
                            TAA Studios presents Project Gemini: an Active Digital Twin Public Demo and Framework. The Acceleration Agency's headquarters in Austin, Texas is digitally replicated and instrumented with a Dense Sensor Fabric for real-time and historical spatial computation of people and assets.
                        </p>
                        <p>
                            The Acceleration Agency has the experience to build an Active Digital Twin of real-world and real-time internet-of-things, assets, processes, and people. An Active Digital Twin intelligence infrastructure gains insights that drive better products, optimize operations and costs and create breakthrough orchestrated experiences.
                        </p>
                        <p>
                            With partnerships such as NVidia's Omniverse, TAA can build Active Digital Twin Platforms to any scale ranging from large cities, ports, highways, hospitals, and stadiums to construction job-site management to real-time warehouse management with hundreds of thousands of trackable objects.
                        </p>
                    </div>
                </div>
            </div>

            <a href="#" class="taa-link" v-scroll-to="'#contact'">
                <div class="taa-link--action">
                    <div class="taa-link--copy">Contact us for a demo</div>
                    <svg class="taa-link--icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.16 9.16"
                         data-v-8fbe9336="">
                        <path fill="currentColor"
                              d="M1,9.16a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42L5.75,2H1.8a1,1,0,0,1,0-2H8.16a1.22,1.22,0,0,1,.38.07h0a1.26,1.26,0,0,1,.32.21h0a.91.91,0,0,1,.2.32A.92.92,0,0,1,9.16,1V7.36a1,1,0,1,1-2,0V3.41L1.71,8.87A1,1,0,0,1,1,9.16Z"
                              data-v-8fbe9336=""></path>
                    </svg>
                </div>
            </a>
        </div>
    </div>

    <!-- logos -->
    <div class="taa-section">
        <div class="taa-container">
            <div class="taa-logo-line">
                <img srcset="img/autonomy-institute.png 100w" sizes="50px" src="img/autonomy-institute.png"/>
                <img srcset="img/omniverse-logo.png 100w" sizes="50px" src="img/omniverse-logo.png"/>
                <img srcset="img/unity-logo.png 100w" sizes="50px" src="img/unity-logo.png"/>                    
            </div>
        </div>
    </div>

    <div class="taa-space"></div>

    <div class="taa-section taa-screen">
        <div class="taa-container">
            <div class="taa-screen--inner">
                <div class="taa-screen--image">
                    <img class="taa-screen--src" src="/img/gemini-hero.jpg" alt="">
                </div>
            </div>
        </div>
    </div>

    <!-- building digital twin -->

    <div class="taa-section taa-photo">
        <div class="taa-container">
            <div class="taa-photo--inner">
                <div class="taa-photo--content">
                    <div class="taa-blurb--headline">Abstraction &amp;<br />Integration Layers</div>
                    <div class="taa-blurb--copy">

                        <p>
                            Multiple abstraction layers allow clients to connect a variety of sensors, databases/CRMs, and Object Integration tools. Project Gemini includes integration examples of SalesForce and Silicon Labs sensor/tag network to show real time location, temperature, audio, and orientation.
                        </p>

                        <div class="taa-blurb--sub-headline">Data Integration Layer</div>

                        <p>
                            Clients can connect a range of data sources including full function CRMs like Salesforce (example in current demo).
                        </p>

                        <div class="taa-blurb--sub-headline">Sensor Fabric Layer</div>

                        <p>
                            Real time sensor information/visualization layer is abstracted so that a range of sensors and tags can be integrated to transmit information. Sensor information that can be displayed includes: location, temperature, audio, orientation, infrared, video, humidity, airflow, etc.
                        </p>

                        <div class="taa-blurb--sub-headline">Messaging Abstraction Layer</div>

                        <p>
                            The Messaging abstraction layer (RabbitMQ) provides a range of viewer(s) (Unity, Omniverse, etc.) connection to backend systems and servers.
                        </p>

                        <div class="taa-blurb--sub-headline">Authentication & Security</div>

                        <p>
                            Authentication and security can be provided including username and password management, permission levels, and multi-factor authentication.
                        </p>

                        <img class="taa-photo--src" src="/img/gemini-screenshot-01.jpg" style="margin-bottom: 20px">
                        <img class="taa-photo--src" src="/img/gemini-screenshot-02.jpg">

                    </div>
                </div>
                <div class="taa-photo--image">
                    <img class="taa-photo--src" src="/img/building-digital-twin.jpg" alt="" style="max-width: 640px; max-height: 1452px">
                </div>
            </div>
        </div>
    </div>

    <!-- Tracking & Integration -->

    <div class="taa-section taa-photo taa-photo-reversed">
        <div class="taa-container">
            <div class="taa-photo--inner">
                <div class="taa-photo--content">
                    <div class="taa-blurb--headline">Real Time<br />Information Display</div>
                    <div class="taa-blurb--copy">

                        <div class="taa-blurb--sub-headline">Viewers</div>

                        <p>
                            Access the Gemini Active Digital Twin from any compatible connected device via the Unity WebGL viewer. Additionally the Gemini Active Digital Twin can be rendered with an unprecedented level of visual fidelity, scale and simulation level via the NVIDIA Omniverse RTX Real Time Renderer.
                        </p>

                        <div class="taa-blurb--sub-headline">View Manipulation</div>

                        <p>
                            View the Active Digital Twin from directly overhead, 3rd Person Isometric or even 1st Person. Various tools for manipulation of the view include rotation, zoom, view presets, changing Location (multiple campuses), and Floor Selection (for multi-story locations).
                        </p>

                        <div class="taa-blurb--sub-headline">Building & Location</div>

                        <p>
                            Manage multiple locations in real time. Each location can display Location Name, Date, Time, Number of People onsite, Weather, etc. in a summary interface element.
                        </p>

                        <div class="taa-blurb--sub-headline">People</div>

                        <p>
                            Each person assigned and wearing a tag can be selected and will display information fed from the Data layer (ex. Salesforce) and Sensor Fabric. Information displayed can include: Name, Title, ID#, Gender, DOB, temperature, audio, etc.
                        </p>

                        <div class="taa-blurb--sub-headline">Items & Inventory</div>

                        <p>
                            Each inventory item (ex. Storage box with network cables) with a tag attached and assigned can be selected and will display information fed from the Data layer (ex. Salesforce) and Sensor Fabric. Information displayed can include: Name, ID#, Quantity, Date of last interaction, temperature, orientation, etc.
                        </p>
                    </div>
                </div>

                <div class="taa-photo--image">
                    <img class="taa-photo--src" src="/img/gemini-screenshot-03.jpg" alt="" style="margin-bottom: 20px">
                    <img class="taa-photo--src" src="/img/gemini-screenshot-04.jpg" alt="" style="margin-bottom: 30px">
                    <img class="taa-photo--src" src="/img/gemini-screenshot-05.jpg" alt="">
                </div>

            </div>
        </div>
    </div>

    <!-- autonomy -->

    <div class="taa-section taa-blurb">
        <div class="taa-container">
            <div class="taa-blurb--inner" style="max-width: unset">
                <div class="taa-blurb--copy">
                    <div class="taa-logo-with-text">
                        <img class="taa-logo-with-text--img" src="/img/autonomy-institute.png" />
                        <div class="taa-logo-with-text--copy">

                            <p>
                                TAA is a member of the Autonomy Institute and is working on Active Digital Twins in cooperation with the Institute. Click here to learn more about the Institute and the Smart Austin project.
                            </p>

                            <a href="https://autonomy.institute/active-digital-twins-and-intelligent-infrastructure/" class="taa-link" target="_blank">
                                <div class="taa-link--action">
                                    <div class="taa-link--copy">autonomy institute</div>
                                    <svg class="taa-link--icon" xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 9.16 9.16"
                                         data-v-8fbe9336="">
                                        <path fill="currentColor"
                                              d="M1,9.16a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42L5.75,2H1.8a1,1,0,0,1,0-2H8.16a1.22,1.22,0,0,1,.38.07h0a1.26,1.26,0,0,1,.32.21h0a.91.91,0,0,1,.2.32A.92.92,0,0,1,9.16,1V7.36a1,1,0,1,1-2,0V3.41L1.71,8.87A1,1,0,0,1,1,9.16Z"
                                              data-v-8fbe9336=""></path>
                                    </svg>
                                </div>
                            </a>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <!-- next project -->

    <div class="taa-next-project">

        <div class="taa-section taa-subhead">
            <div class="taa-container">
                <div class="taa-subhead--inner">
                    <svg class="taa-slash" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 18">
                        <path fill="currentColor" d="M6.31619 17.9998L0 0L9.52546 0.000199749L16 18L6.31619 17.9998Z"/>
                    </svg>
                    <div class="taa-subhead--copy">Next Solution</div>
                </div>
            </div>
        </div>

        <div class="taa-section taa-blurb">
            <div class="taa-container">
                <div class="taa-blurb--inner">
                    <router-link :to="'experience'" class="taa-link">
                        <div class="taa-link--action">
                            <div class="taa-link--copy">Experience Orchestration</div>
                            <svg class="taa-link--icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.16 9.16">
                                <path fill="currentColor"
                                      d="M1,9.16a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42L5.75,2H1.8a1,1,0,0,1,0-2H8.16a1.22,1.22,0,0,1,.38.07h0a1.26,1.26,0,0,1,.32.21h0a.91.91,0,0,1,.2.32A.92.92,0,0,1,9.16,1V7.36a1,1,0,1,1-2,0V3.41L1.71,8.87A1,1,0,0,1,1,9.16Z"/>
                            </svg>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>

        <div class="taa-section taa-screen">
            <div class="taa-container">
                <div class="taa-screen--inner">
                    <div class="taa-screen--image">
                        <img class="taa-screen--src" src="img/experience-next.jpg" alt=""/>
                    </div>
                </div>
            </div>
        </div>

        <div class="taa-space"></div>

    </div>

</template>

<script>

import {LocalStorageKeys} from '@/libs/const.js'
import ls from 'local-storage';
import site from './site.json';

export default {
    name: 'ProjectGemini',
    components: {},
    data: function () {
        return {
            isFirstTimeOnSite: 0,
            info: null
        }
    },
    watch: {
        $route() {
            this.info = site['sections'][this.$route.name]
            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 1)
        }
    },
    mounted() {
        window.scrollTo(0, 0);
    },
    created() {

        this.info = site['sections'][this.$route.name]

        this.isFirstTimeOnSite = ls(LocalStorageKeys.FirstTimeOnSite);
        if (this.isFirstTimeOnSite === null || this.isFirstTimeOnSite === false) {
            ls(LocalStorageKeys.FirstTimeOnSite, false);
        } else {
            this.isFirstTimeOnSite = true;
        }
    }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

.taa-blurb--sub-headline{
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 20px;
}

/*a {
    color: #42b983;
}*/

.taa-logo-with-text {
    display: flex;
    align-items: flex-start;
    gap: 40px;
}

.taa-logo-with-text--img {
    width: 100%;
    max-width: 300px;
}

.taa-logo-with-text--copy {
    flex: 1;
}

@media (max-width: 767px) {
    .taa-logo-with-text {
        flex-direction: column;
    }
}

</style>
