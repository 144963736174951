<template>

    <div class="taa-section taa-footer" id="contact">
        <div class="taa-container">
            <div class="taa-footer--inner">
                <div class="taa-blurb--headline">Contact Us</div>
                <div class="taa-blurb--copy">
                    <p>
                        Nestled in the heart of vibrant East Austin, our national headquarters is a reflection of the active, innovative and friendly culture of our company.
                    </p>
                    <p>
                        Contact us today to schedule a virtual or physical visit. 
                    </p>
                </div>
                <div class="taa-footer--table">
                    <div class="taa-footer--row">
                        <div class="taa-footer--label">Email</div>
                        <div class="taa-footer--value"><a href="mailto:contact@taa.io">contact@taa.io</a></div>
                    </div>
                    <div class="taa-footer--row">
                        <div class="taa-footer--label">Address</div>
                        <div class="taa-footer--value">
                            2005 East Cesar Chavez Street<br>
                            Austin, TX 78702
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</template>

<script>
//import VideoBackground from 'vue-responsive-video-background-player'

export default {
  name: 'Footer'
}


</script>