<template>

    <div class='ukraine'>
      <a href='https://donate.redcrossredcrescent.org/ua/donate/~my-donation?_cv=1' target='_blank'><img class='ukraine' src="/img/ukr.svg" alt=""></a>
    </div>

</template>

<script>
//import VideoBackground from 'vue-responsive-video-background-player'




</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

.ukraine {
  z-index: 9999;
  position: absolute;
  width: 100%;
  height: 100vh;
  background: linear-gradient(to bottom, #005BBB 50%, #FFD500 50%); 
}

</style>



