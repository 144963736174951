<template>
      <div class="taa-space"></div>

        <div class="taa-section taa-video">
            <div class="taa-container">
                <div class="taa-video--inner">
                      <video-player  class="taa-video--src"
                                     ref="videoPlayer"
                                     :options="playerOptions"
                                     >
                    </video-player>
                  </div>
            </div>
        </div>

        <div class="taa-section taa-subhead">
            <div class="taa-container">
                <div class="taa-subhead--inner">
                    <svg class="taa-slash" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 18"><path fill="currentColor" d="M6.31619 17.9998L0 0L9.52546 0.000199749L16 18L6.31619 17.9998Z"/></svg>
                    <div class="taa-subhead--copy">Meet the team</div>
                </div>
            </div>
        </div>
        
        <div class="taa-section taa-team">
            <div class="taa-container">
                <div class="taa-team--inner">
                    <div class="taa-team--member">
                        <img class="taa-team--photo" src="img/rodney-white.jpg" alt="" />
                        <div class="taa-team--name">Rodney White</div>
                    </div>
                    <div class="taa-team--member">
                        <img class="taa-team--photo" src="img/starr-long.jpg" alt="" />
                        <div class="taa-team--name">Starr Long</div>
                    </div>
                    <div class="taa-team--member">
                        <img class="taa-team--photo" src="img/joye-price.jpg" alt="" />
                        <div class="taa-team--name">Joye Price</div>
                    </div>
                    <div class="taa-team--member">
                        <img class="taa-team--photo" src="img/darren-wong.jpg" alt="" />
                        <div class="taa-team--name">Darren Wong</div>
                    </div>
                    <div class="taa-team--member">
                        <img class="taa-team--photo" src="img/stacey-wilhelm.jpg" alt="" />
                        <div class="taa-team--name">Stacey WilheLm</div>
                    </div>
                    <div class="taa-team--member">
                        <img class="taa-team--photo" src="img/atul-tewari.jpg" alt="" />
                        <div class="taa-team--name">Atul Tewari</div>
                    </div>
                    <div class="taa-team--member">
                        <img class="taa-team--photo" src="img/mindy-wong.jpg" alt="" />
                        <div class="taa-team--name">Mindy Wong</div>
                    </div>
                    <div class="taa-team--member">
                        <img class="taa-team--photo" src="img/richard-primeaux.jpg" alt="" />
                        <div class="taa-team--name">Richard Primeaux</div>
                    </div>
                    <div class="taa-team--member">
                        <img class="taa-team--photo" src="img/doh-lee.jpg" alt="" />
                        <div class="taa-team--name">Doh Lee</div>
                    </div>
                    <div class="taa-team--member">
                        <img class="taa-team--photo" src="img/mike-schipper.jpg" alt="" />
                        <div class="taa-team--name">Mike Schipper</div>
                    </div>
                    <div class="taa-team--member">
                        <img class="taa-team--photo" src="img/david-land.jpg" alt="" />
                        <div class="taa-team--name">David Land</div>
                    </div>
                    <div class="taa-team--member">
                        <img class="taa-team--photo" src="img/kathy-tieu.jpg" alt="" />
                        <div class="taa-team--name">Kathy Tieu</div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="taa-section taa-subhead">
            <div class="taa-container">
                <div class="taa-subhead--inner">
                    <svg class="taa-slash" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 18"><path fill="currentColor" d="M6.31619 17.9998L0 0L9.52546 0.000199749L16 18L6.31619 17.9998Z"/></svg>
                    <div class="taa-subhead--copy">Our Values</div>
                </div>
            </div>
        </div>
        
        <div class="taa-section taa-photo">
            <div class="taa-container">
                <div class="taa-photo--inner">
                    <div class="taa-photo--content">
                        <div class="taa-blurb--headline">
                            We believe in thinking differently.
                        </div>
                        <div class="taa-blurb--copy">
                            <p>
                                We employ a horizontal approach to management, allowing every team member to voice their opinion and communicate freely with one another to get fantastic results for our clients.
                            </p>
                        </div>
                    </div>
                    <div class="taa-photo--image">
                        <img class="taa-photo--src" src="img/different.jpg" alt="" />
                    </div>
                </div>
            </div>
        </div>
        
        <div class="taa-section taa-photo taa-photo-reversed">
            <div class="taa-container">
                <div class="taa-photo--inner">
                    <div class="taa-photo--content">
                        <div class="taa-blurb--headline">
                            A dedicated team of experts.
                        </div>
                        <div class="taa-blurb--copy">
                            <p>
                                Our team holds multiple world records, and over a hundred and fifty years of combined experience, working with the biggest brands in the world.
                            </p>
                        </div>
                    </div>
                    <div class="taa-photo--image">
                        <img class="taa-photo--src" src="img/dedicated.jpg" alt="" />
                    </div>
                </div>
            </div>
        </div>
        
        <div class="taa-space"></div> 


</template>

<script>

import site from './site.json';

export default {
  name: 'About',
  components: {
  },
  data: function () {
    return {
      isFirstTimeOnSite: 0,
      info: site,
      playerOptions: {
          // videojs options
        autoplay: true,
        controls: false,
        controlBar: {
            timeDivider: false,
            durationDisplay: false
        },
        muted: true,
        loop: true,
        language: 'en',
        playbackRates: [1.0],
        sources: [{
            type: "video/mp4",
            src: "/video/taa-website-b-roll.mp4"
        }],
        poster: "/img/video.png",
        width: 1280,
        aspectRatio: '16:9',
        }
      }
  },
  watch:{
    $route (){
      setTimeout( () => {
        window.scrollTo(0, 0);
      },1)
    }
  },
  mounted() {
      window.scrollTo(0, 0);
  },

  computed: {
    player() {
      return this.$refs.videoPlayer.player
    }
  },
  methods: {
    // listen event
    onPlayerPlay(player) {
      console.log('player play!', player)
    },
    onPlayerPause(player) {
      console.log('player pause!', player)
    },
    // ...player event

    // or listen state event
    playerStateChanged(playerCurrentState) {
      console.log('player current update state', playerCurrentState)
    },

    // player is ready
    playerReadied(player) {
      console.log('the player is readied', player)
      // you can use it to do something...
      // player.[methods]
    }
  }

}


</script>