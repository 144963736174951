<template>

    <div class="taa-section taa-blurb">
        <div class="taa-container">
            <div class="taa-blurb--inner">
                <div class="taa-blurb--headline">Roboflow Exporter Extension for Omniverse</div>
                <div class="taa-tag" href="#">
                    <div class="taa-tag--title">
                        <div class="taa-tag--copy">Roboflow</div>
                        <svg class="taa-tag--icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 18">
                            <path fill="currentColor"
                                  d="M6.31619 17.9998L0 0L9.52546 0.000199749L16 18L6.31619 17.9998Z"
                            ></path>
                        </svg>
                    </div>
                    <div class="taa-tag--extra">Computer vision models</div>
                </div>
                <div class="taa-blurb--copy">
                    <p class="taa-blurb--copy-large">
                        Generate synthetic data for Machine Learning / AI Training data and automatically send to
                        Roboflow with TAA’s Roboflow Exporter Extension for Omniverse.
                    </p>
                    <p class="taa-blurb--copy-large">
                        No Coding Required.
                    </p>
                    <p>
                        This extension allows you to automatically highlight coordinates and automate domain
                        randomization with a simple UI and automatically upload the data to the correct Roboflow Project
                        for model training.
                    </p>
                </div>

                <div style="width: 100%; text-align: center">
                    Download and install the<br />
                    <a style="text-align: center" href="#" class="taa-link" target="_blank">
                        <div class="taa-link--action">
                            <div class="taa-link--copy">Roboflow Exporter Extension</div>
                            <svg class="taa-link--icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.16 9.16">
                                <path fill="currentColor"
                                      d="M1,9.16a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42L5.75,2H1.8a1,1,0,0,1,0-2H8.16a1.22,1.22,0,0,1,.38.07h0a1.26,1.26,0,0,1,.32.21h0a.91.91,0,0,1,.2.32A.92.92,0,0,1,9.16,1V7.36a1,1,0,1,1-2,0V3.41L1.71,8.87A1,1,0,0,1,1,9.16Z"
                                ></path>
                            </svg>
                        </div>
                    </a>
                </div>

                <div class="taa-image">
                    <img src="/img/roboflow-01.png" alt="Roboflow Omniverse Extension" class="taa-image--src">
                </div>

                <div class="taa-full-width">
                    <div class="taa-caption">
                        Select your target model(s) camera parameters, and replicator settings within <strong>NVIDIA
                        Omniverse</strong>.
                    </div>
                </div>

                <div class="taa-image">
                    <img src="/img/roboflow-02.png" alt="Roboflow Omniverse Extension" class="taa-image--src">
                </div>

                <div class="taa-full-width">
                    <img src="/img/roboflow-arrow.png" alt="Roboflow Omniverse Extension"
                         class="taa-roboflow-arrow">
                    <div class="taa-caption">
                        Running <strong>Roboflow</strong> Exporter automatically, renders, highlights and exports
                        targets to Roboflow for model training.
                    </div>
                </div>

                <div class="taa-image" style="padding-left: 14px">
                    <img src="/img/roboflow-03.png" alt="Roboflow Omniverse Extension" class="taa-image--src">
                </div>

                <div class="taa-full-width">
                    <div class="taa-caption">
                        Works with single and multiple selected objects.
                    </div>
                </div>

                <div class="taa-image">
                    <img src="/img/roboflow-04.png" alt="Roboflow Omniverse Extension" class="taa-image--src">
                </div>

                <div style="width: 100%; text-align: center; margin-bottom: 100px">
                    Download and install the<br />
                    <a href="#" class="taa-link" style="margin-bottom: 0px" target="_blank">
                        <div class="taa-link--action">
                            <div class="taa-link--copy">Roboflow Exporter Extension</div>
                            <svg class="taa-link--icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.16 9.16">
                                <path fill="currentColor"
                                      d="M1,9.16a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42L5.75,2H1.8a1,1,0,0,1,0-2H8.16a1.22,1.22,0,0,1,.38.07h0a1.26,1.26,0,0,1,.32.21h0a.91.91,0,0,1,.2.32A.92.92,0,0,1,9.16,1V7.36a1,1,0,1,1-2,0V3.41L1.71,8.87A1,1,0,0,1,1,9.16Z"
                                ></path>
                            </svg>
                        </div>
                    </a><br />
                    <small>for plugin support please contact us</small>
                </div>

            </div>
        </div>
    </div>


</template>

<script>

    import { LocalStorageKeys } from '@/libs/const.js'
    import ls from 'local-storage';
    import site from './site.json';


    export default {
        name: 'Section',
        components: {},
        data: function () {
            return {
                isFirstTimeOnSite: 0,
                info: null
            }
        },
        watch: {
            $route() {
                this.info = site['sections'][this.$route.name]
                setTimeout(() => {
                    window.scrollTo(0, 0);
                }, 1)
            }
        },
        mounted() {
            window.scrollTo(0, 0);
        },
        created() {

            this.info = site['sections'][this.$route.name]

            this.isFirstTimeOnSite = ls(LocalStorageKeys.FirstTimeOnSite);
            if (this.isFirstTimeOnSite === null || this.isFirstTimeOnSite === false) {
                ls(LocalStorageKeys.FirstTimeOnSite, false);
            } else {
                this.isFirstTimeOnSite = true;
            }
        }
    }
</script>

<style scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

.taa-caption {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 0 150px;
    font-size: .85em;
}

.taa-full-width {
    width: 100%;
    position: relative;
}

.taa-roboflow-arrow {
    position: absolute;
    height: 100px;
    left: 47px;
}

@media only screen and (max-width: 768px) {
    .taa-caption {
        padding: 0 30px;
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: .7em;
        line-height: 1.3em;
    }
    .taa-roboflow-arrow {
        height: 65px;
        left: 20px;
    }
}

</style>
