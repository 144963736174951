<template>


  <div class="Landing" >
      <div ref='videoBackground'>
        <video-background v-if='showVideo' 
          src="/video/taa-website-b-roll.mp4"
          objectFit='contain'
          style="max-height: 100%; height: 100%;"
        >
        </video-background>
      </div>
<div >

<div v-if='!showVideo' >
        <br><br><br><br><img class='landing-large-logo' src="@/assets/img/taa_logo_circle.png">

        <div class="text-center vertical-center">
                        <br><br>
                        <div class='tag-line'>The Acceleration Agency is the only digital innovation &amp; product design <br>firm that has the strategic experience, digital expertise, and agility to <br>successfully scale in order to solve complex challenges in both<br> consumer and enterprise spaces.<br><br>We believe in thinking differently. We believe in acting differently. 
                          <br><br><br>Innovation. Delivered.
                        </div>
                       </div>

        </div>
</div>
    <footer class="site-footer text-center">This site is <a href="/humans.txt">hand-crafted by TAA.</a><br>© 2016 The Acceleration Agency. All rights reserved.
     </footer>
  </div>

    <Particles
            id="tsparticles"
            :particlesInit="particlesInit"
            :particlesLoaded="particlesLoaded"
            url="./particles.json"
    />


</template>

<script>

import VideoBackground from 'vue-responsive-video-background-player'
import {LocalStorageKeys} from '@/libs/const.js'
import ls from 'local-storage';



export default {
  name: 'Landing',
  components: {
      VideoBackground
  },
  data: function () {
    return {
      isFirstTimeOnSite: 0,
      showVideo:0
    }
  },
  mounted() {
    console.log(this.$refs.videoBackground)
    if (this.showVideo) {
      this.$refs.videoBackground.style.height = window.innerHeight / 1.5 + "px";
    }

    window.onresize = () => {
      if (this.showVideo) {
        this.$refs.videoBackground.style.height = window.innerHeight / 1.5 + "px";
      }
    };

    setTimeout(() => {
      this.showVideo = 1;
      this.$nextTick ( () => {
        this.$refs.videoBackground.style.height = window.innerHeight / 1.5 + "px";
      })
    }, 5000)

  },
  created() {

    console.log('Landing Created');
    this.isFirstTimeOnSite = ls(LocalStorageKeys.FirstTimeOnSite);
    console.log('Landing.isFirstTimeOnSite', typeof this.isFirstTimeOnSite, this.isFirstTimeOnSite);
    if (this.isFirstTimeOnSite === null  ||this.isFirstTimeOnSite === false) {
      console.log(this.isFirstTimeOnSite)
      ls(LocalStorageKeys.FirstTimeOnSite, false);
    } else {
      this.isFirstTimeOnSite = true;
    }


  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.tag-line {
  font-size: 22px;
  font-weight: 600;
}
.Landing {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
}


#tsparticles canvas {
    display: block;
    vertical-align: bottom;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    -webkit-transition: opacity .8s ease, -webkit-transform 1.4s ease;
    transition: opacity .8s ease, transform 1.4s ease
}

#tsparticles {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -10;
    top: 0;
    left: 0
}
</style>
