<template>
      <div class="taa-space"></div>

      <div class="taa-section taa-heading">
          <div class="taa-container">
              <div class="taa-heading--inner">
                  <div class="taa-heading--copy" v-html="info.title" ></div>
              </div>
          </div>
      </div>

      <div class="taa-section taa-project">
          <div class="taa-container">
              <div class="taa-project--inner">
                  <div class="taa-project--brief">
                      <div class="taa-blurb--headline"  v-html="info.subhead" />
                      <div class="taa-blurb--copy"  v-html="info.content" />
                  </div>
              </div>
          </div>
      </div>
      
      <div class="taa-space"></div>
      
      <div class="taa-image">
          <img class="taa-image--src" :src="info.hero" alt="" />
      </div>


      <div v-for="client in info.clients" :key="client">
        <div class="taa-divider"></div>
        
        <div class="taa-section taa-blurb">
            <div class="taa-container">
                <div class="taa-blurb--inner">
                    <div class="taa-blurb--headline" v-html="client.company" />
                    <div class="taa-tag" href="#">
                        <div class="taa-tag--title">
                            <div class="taa-tag--copy"  v-html="client.tagline" />
                            <svg class="taa-tag--icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 18"><path fill="currentColor" d="M6.31619 17.9998L0 0L9.52546 0.000199749L16 18L6.31619 17.9998Z"/></svg>
                        </div>
                        <div class="taa-tag--extra" v-html='client.tags[0]' />
                    </div>
                    <div class="taa-blurb--copy" v-html='client.content' />
                </div>
            </div>
        </div>
        
        <div class="taa-section taa-screen">
            <div class="taa-container">
                <div class="taa-screen--inner">
                    <div class="taa-screen--image">
                        <img class="taa-screen--src" :src="client.hero" alt="">
                    </div>
                    <div v-if='client.hero2' class="taa-screen--image">
                        <img class="taa-screen--src" :src="client.hero2" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="taa-space"></div>
    <div class="taa-space"></div>

    <div class="taa-next-project">
    
        <div class="taa-section taa-subhead">
            <div class="taa-container">
                <div class="taa-subhead--inner">
                    <svg class="taa-slash" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 18"><path fill="currentColor" d="M6.31619 17.9998L0 0L9.52546 0.000199749L16 18L6.31619 17.9998Z"/></svg>
                    <div class="taa-subhead--copy">Next Solution</div>
                </div>
            </div>
        </div>

        <div class="taa-section taa-blurb">
            <div class="taa-container">
                <div class="taa-blurb--inner">
                    <router-link :to="info.nextSection" class="taa-link">
                        <div class="taa-link--action">
                            <div class="taa-link--copy"  v-html="info.nextSectionTitle" />
                            <svg class="taa-link--icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.16 9.16"><path fill="currentColor" d="M1,9.16a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42L5.75,2H1.8a1,1,0,0,1,0-2H8.16a1.22,1.22,0,0,1,.38.07h0a1.26,1.26,0,0,1,.32.21h0a.91.91,0,0,1,.2.32A.92.92,0,0,1,9.16,1V7.36a1,1,0,1,1-2,0V3.41L1.71,8.87A1,1,0,0,1,1,9.16Z"/></svg>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>           

        <div class="taa-section taa-screen">
            <div class="taa-container">
                <div class="taa-screen--inner">
                    <div class="taa-screen--image">
                        <img class="taa-screen--src" src="img/experience-next.jpg" alt="" />
                    </div>
                </div>
            </div>
        </div>
    
        <div class="taa-space"></div> 
    
    </div>
      



</template>

<script>

import {LocalStorageKeys} from '@/libs/const.js'
import ls from 'local-storage';
import site from './site.json';


export default {
  name: 'Section',
  components: {
  },
  data: function () {
    return {
      isFirstTimeOnSite: 0,
      info: null
    }
  },
  watch:{
    $route (){
      this.info = site['sections'][this.$route.name]
      setTimeout( () => {
        window.scrollTo(0, 0);
      },1)
    }
  },
  mounted() {
      window.scrollTo(0, 0);
  },
  created() {

    this.info = site['sections'][this.$route.name]

    this.isFirstTimeOnSite = ls(LocalStorageKeys.FirstTimeOnSite);
    if (this.isFirstTimeOnSite === null  ||this.isFirstTimeOnSite === false) {
      ls(LocalStorageKeys.FirstTimeOnSite, false);
    } else {
      this.isFirstTimeOnSite = true;
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
