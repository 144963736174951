
const KeyCodes = {
    Left: 37,
    Up: 38,
    Right: 39,
    Down: 40,
    Enter: 13,
    Back: 461,
    Portal: 602,
    Esc: 27
};

const Events = {
    TermainlOpened: 'termina-window-open',
    TermainlClosed: 'termina-window-closed'
};

const LocalStorageKeys = {
    FirstTimeOnSite: 'taa-first-time-on-site',
    ShowUKR: 'taa-show-ukr-support'
};



export { KeyCodes, Events, LocalStorageKeys };
