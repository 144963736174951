import { createWebHashHistory, createRouter } from "vue-router";
import { trackRouter } from "vue-gtag-next";
import HomeOld from "@/components/HomeOld.vue";
import Home from "@/components/Home.vue";
import Section from "@/components/Section.vue";
import About from "@/components/About.vue";
import TAAStudios from "@/components/TAAStudios";
import ProjectGemini from "@/components/ProjectGemini";
import Roboflow from "@/components/Roboflow.vue";
import Optics from "@/components/Optics.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/2",
    name: "HomeOld",
    component: HomeOld,
  },
  {
    path: "/About",
    name: "About",
    component: About,
  },
  {
    path: "/experience",
    name: "experience",
    component: Section,
  },
  {
    path: "/xiot",
    name: "xiot",
    component: Section,
  },
  {
    path: "/development",
    name: "development",
    component: Section,
  },
  {
    path: "/applications",
    name: "applications",
    component: Section,
  },
  {
    path: "/taa-studios",
    name: "TAA Studios",
    component: TAAStudios
  },
  {
    path: "/project-gemini",
    name: "Project Gemini",
    component: ProjectGemini
  },
  {
    path: "/roboflow",
    name: "Roboflow",
    component: Roboflow
  },
  {
    path: "/optics",
    name: "optics",
    component: Optics
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

trackRouter(router);

export default router;